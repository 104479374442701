import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import DashboardCombined from './pages/DashboardCombined';
import ClinicalGenerator from './pages/ClinicalGenerator';
import DashboardCalls from './pages/DashboardCalls';
import DashboardClients from './pages/DashboardClients';
import DashboardWaitlist from './pages/DashboardWaitlist';
import MRPInterpretiveSummary from './pages/MRPInterpretiveSummary';
import AskTheCRM from './pages/AskTheCRM';
import AdminPanel from './pages/adminPanel';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';

// Create an AdminRoute component for admin-only access
const AdminRoute = ({ children }) => {
  const { auth } = useAuth();
  
  if (!auth.user || auth.user.role !== 'admin') {
    return <Navigate to="/landing" replace />;
  }
  
  return <ProtectedRoute>{children}</ProtectedRoute>;
};

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/landing"
            element={
              <ProtectedRoute>
                <LandingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-combined"
            element={
              <ProtectedRoute>
                <DashboardCombined />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clinical-generator"
            element={
              <ProtectedRoute>
                <ClinicalGenerator />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-calls"
            element={
              <ProtectedRoute>
                <DashboardCalls />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-clients"
            element={
              <ProtectedRoute>
                <DashboardClients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-waitlist"
            element={
              <ProtectedRoute>
                <DashboardWaitlist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mrp-interpretive-summary"
            element={
              <ProtectedRoute>
                <MRPInterpretiveSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ask-the-crm"
            element={
              <ProtectedRoute>
                <AskTheCRM />
              </ProtectedRoute>
            }
          />
          {/* Add Admin Panel Route */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminPanel />
              </AdminRoute>
            }
          />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
