import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import lastdoor from '../resources/lastdoor.png';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout();
    navigate('/');
  };

  return (
    <AppBar position="static" sx={{
      background: 'rgba(128, 128, 128, 0.25)',
      backdropFilter: 'blur(12px)',
      WebkitBackdropFilter: 'blur(12px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ 
            mr: 2,
            color: '#424242',
            '& .MuiSvgIcon-root': {
              fontSize: 32,
              fontWeight: 'bold',
            },
          }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center' }}>
          <img 
            src={lastdoor} 
            alt="Logo" 
            style={{ 
              maxWidth: '25%', 
              maxHeight: 50, 
              padding: '15px 0', 
              marginRight: 16,
              filter: 'brightness(0.3)'
            }} 
          />
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#424242' }}>
            MultiTool
          </Typography>
        </Box>

        {auth.token && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                marginRight: 2, 
                color: '#424242',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              Welcome, <strong>{auth.user.username}</strong>
            </Typography>
            <Button 
              sx={{
                background: 'rgba(255, 255, 255, 0.2)',
                backdropFilter: 'blur(12px)',
                WebkitBackdropFilter: 'blur(12px)',
                color: '#424242',
                border: '1px solid rgba(255, 255, 255, 0.3)',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.3)',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }
              }} 
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        )}

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigation('/landing')}>Home</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-combined')}>Dashboard Combined</MenuItem>
          <MenuItem onClick={() => handleNavigation('/clinical-generator')}>Clinical Generator</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-calls')}>Dashboard Calls</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-clients')}>Dashboard Clients</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-waitlist')}>Dashboard Waitlist</MenuItem>
          <MenuItem onClick={() => handleNavigation('/mrp-interpretive-summary')}>MRP Interpretive Summary</MenuItem>
          <MenuItem onClick={() => handleNavigation('/ask-the-crm')}>Ask the CRM</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;