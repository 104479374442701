// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

export const AuthContext = createContext();

// Create useAuth hook
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    
    // Validate stored data
    if (!token || !user) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return { token: null, user: null };
    }
    
    try {
      return {
        token,
        user: JSON.parse(user)
      };
    } catch {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return { token: null, user: null };
    }
  });

  useEffect(() => {
    // Check token on mount and periodically
    const validateToken = () => {
      const token = localStorage.getItem('token');
      if (!token) {
        logout();
      }
    };

    validateToken();
    const interval = setInterval(validateToken, 60000); // Check every minute
    
    return () => clearInterval(interval);
  }, []);

  const login = (token, user) => {
    if (!token || !user) {
      console.error('Invalid login data');
      return;
    }
    
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setAuth({ token, user });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuth({ token: null, user: null });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
