// src/pages/ClinicalGenerator.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ReactMarkdown from 'react-markdown';
import { saveAs } from 'file-saver';

const ClinicalGenerator = () => {
  const navigate = useNavigate();
  const { auth, logout } = useAuth();
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [reportType, setReportType] = useState('interpretive_summary'); // Default to interpretive_summary
  const [reportContent, setReportContent] = useState('');
  const [reportFormat, setReportFormat] = useState('markdown'); // Default to markdown
  const [error, setError] = useState('');

  const handleError = (error) => {
    console.error('API Error:', error);
    if (error.response?.status === 401) {
      setError('Session expired. Please log in again.');
      logout();
      navigate('/');
    } else {
      setError(error.response?.data?.message || 'An error occurred. Please try again.');
    }
  };

  const fetchClients = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/list`,
        {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setClients(response.data.clients);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const generateReport = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/report`,
        {
          clientId: selectedClient,
          reportType,
          format: reportFormat,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setReportContent(response.data.reportContent);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = () => {
    const blob = new Blob([reportContent], { type: 'text/markdown;charset=utf-8' });
    saveAs(blob, `${reportType}_report.md`);
  };

  return (
    <>
      <Header />
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Clinical Generator
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchClients}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : 'Fetch Clients'}
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Paper>

        {clients.length > 0 && (
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <FormControl fullWidth>
              <InputLabel>Select Client</InputLabel>
              <Select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.number} | {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select Report Type</InputLabel>
              <Select
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <MenuItem value="interpretive_summary">Interpretive Summary</MenuItem>
                <MenuItem value="discharge_summary">Discharge Summary</MenuItem>
                <MenuItem value="progress_report">Progress Report</MenuItem>
                <MenuItem value="treatment_flow_chart">Treatment Flow Chart</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select Report Format</InputLabel>
              <Select
                value={reportFormat}
                onChange={(e) => setReportFormat(e.target.value)}
              >
                <MenuItem value="plain">Plain Text</MenuItem>
                <MenuItem value="markdown">Markdown</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={generateReport}
              disabled={loading || !selectedClient || !reportType}
              fullWidth
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate Report'}
            </Button>
          </Paper>
        )}

        {reportContent && (
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              Generated Report
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={downloadReport}
              >
                Download Report
              </Button>
            </Box>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>
              {reportFormat === 'markdown' ? (
                <ReactMarkdown>{reportContent}</ReactMarkdown>
              ) : (
                reportContent
              )}
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default ClinicalGenerator;