     // src/components/Login.js
     import React, { useState, useContext } from 'react';
     import axios from 'axios';
     import { useNavigate } from 'react-router-dom';
     import { AuthContext } from '../contexts/AuthContext';
     import lastdoor from '../resources/lastdoor.png'; // Ensure correct path
     
     const Login = () => {
       const navigate = useNavigate();
       const { login } = useContext(AuthContext);
     
       const [form, setForm] = useState({ username: '', password: '' });
       const [error, setError] = useState('');
     
       const handleChange = e => {
         setForm({ ...form, [e.target.name]: e.target.value });
       };
     
       const handleSubmit = async e => {
         e.preventDefault();
         setError('');
         console.log('Submitting form:', form);

         try {
           const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
          //  const apiUrl = 'http://localhost:3001/api';
          console.log('API URL:', apiUrl);
           console.log('Attempting to connect to:', apiUrl);
           console.log('API URL:', process.env.REACT_APP_API_URL);
           const res = await axios.post(`${apiUrl}/auth/login`, form);
           console.log('Login response:', res.data);
           login(res.data.token, res.data.user);
           navigate('/landing');
         } catch (err) {
           console.error('Login error:', err);
           if (err.response) {
             console.error('Error response:', err.response.data);
             console.error('Error status:', err.response.status);
             console.error('Error headers:', err.response.headers);
           } else if (err.request) {
             console.error('Error request:', err.request);
           } else {
             console.error('Error message:', err.message);
           }
           setError(err.response?.data?.message || 'Login failed');
         }
       };
     
       return (
         <div className="login-container">
           <img src={lastdoor} alt="Logo" className="logo" style={{ maxWidth: '25%', height: 'auto' }} />
           <h1>MultiTool</h1>
           <form onSubmit={handleSubmit} className="login-form">
             {error && <p className="error">{error}</p>}
             <div>
               <label>Username:</label>
               <input
                 type="text"
                 name="username"
                 value={form.username}
                 onChange={handleChange}
                 required
                 autoFocus
               />
             </div>
             <div>
               <label>Password:</label>
               <input
                 type="password"
                 name="password"
                 value={form.password}
                 onChange={handleChange}
                 required
               />
             </div>
             <button type="submit">Login</button>
           </form>
         </div>
       );
     };
     
     export default Login;
