import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', role: 'user' });
  const [editUser, setEditUser] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const { auth } = useAuth();

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setUsers(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please check your permissions and try again.');
      setUsers([]);
    }
  }, [auth.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const validateForm = (user) => {
    if (!user.username || user.username.length < 3) {
      throw new Error('Username must be at least 3 characters long');
    }
    if (user.password && user.password.length < 6) {
      throw new Error('Password must be at least 6 characters long');
    }
    if (!user.role) {
      throw new Error('Role must be selected');
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      validateForm(newUser);
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/users`, newUser, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setNewUser({ username: '', password: '', role: 'user' });
      setSuccess('User created successfully');
      fetchUsers();
    } catch (error) {
      setError(error.message || 'Error creating user');
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      validateForm(editUser);
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/users/${editUser.id}`, editUser, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setEditUser(null);
      setSuccess('User updated successfully');
      fetchUsers();
    } catch (error) {
      setError(error.message || 'Error updating user');
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/admin/users/${deleteUserId}`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setSuccess('User deleted successfully');
      setDeleteUserId(null);
      setOpenDialog(false);
      fetchUsers();
    } catch (error) {
      setError('Error deleting user');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
  };

  const confirmDelete = (id) => {
    setDeleteUserId(id);
    setOpenDialog(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {editUser ? 'Edit User' : 'Create New User'}
        </Typography>
        <Box component="form" onSubmit={editUser ? handleUpdateUser : handleCreateUser}>
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            value={editUser ? editUser.username : newUser.username}
            onChange={(e) => editUser 
              ? setEditUser({ ...editUser, username: e.target.value })
              : setNewUser({ ...newUser, username: e.target.value })
            }
          />
          {!editUser && (
            <TextField
              fullWidth
              margin="normal"
              type="password"
              label="Password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              value={editUser ? editUser.role : newUser.role}
              onChange={(e) => editUser
                ? setEditUser({ ...editUser, role: e.target.value })
                : setNewUser({ ...newUser, role: e.target.value })
              }
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
            >
              {editUser ? 'Update User' : 'Create User'}
            </Button>
            {editUser && (
              <Button
                variant="outlined"
                onClick={() => setEditUser(null)}
              >
                Cancel Edit
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          User List
        </Typography>
        {users.length > 0 ? (
          <List>
            {users.map((user) => (
              <ListItem key={user.id}>
                <ListItemText
                  primary={user.username}
                  secondary={`Role: ${user.role}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => setEditUser(user)}
                    sx={{ mr: 1 }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => confirmDelete(user.id)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="textSecondary">
            No users found or unable to fetch user list.
          </Typography>
        )}
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteUser} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success/Error Messages */}
      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
      >
        <Alert severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminPanel;