import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Paper, Typography, CircularProgress, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Create an axios instance with the base URL of your backend server
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Ensure this points to your backend server
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`, // Include auth headers if needed
  },
});

const DashboardCombined = () => {
  const [loading, setLoading] = useState(false);
  const [callData, setCallData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    new Date().toISOString().split('T')[0],
  ]);

  const handleGetData = async () => {
    setLoading(true);

    try {
      const [startDate, endDate] = dateRange;
      const response = await api.get('/dashboard-combined', {
        params: { start_date: startDate, end_date: endDate }
      });
      
      console.log('API Response:', response.data); // Add this for debugging
      
      const { calls, contacts } = response.data;
      setCallData(calls || []);
      setContactData({ contacts: contacts || [] });
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again later.');
      setCallData(null);
      setContactData(null);
    } finally {
      setLoading(false);
    }
  };

  const renderContactData = () => {
    if (!contactData?.contacts?.length) {
      return <Typography>No contact data available.</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Phone</strong></TableCell>
              <TableCell><strong>City</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Referral Source</strong></TableCell>
              <TableCell><strong>Last Contact Date</strong></TableCell>
              <TableCell><strong>Funding Result</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactData.contacts.map((contact) => (
              <TableRow 
                key={contact.contact_id} 
                sx={{ 
                  backgroundColor: contact.custom_243 === 'Accepted' ? '#4caf50' : 
                                 contact.custom_243 === 'In Progress' ? '#a5d6a7' : 
                                 contact.custom_243 === 'Not Accepted' ? '#f44336' : 
                                 contact.custom_243 === 'Inactive' ? '#ffeb3b' : 'transparent' 
                }}
              >
                <TableCell><strong>{contact.display_name}</strong></TableCell>
                <TableCell><strong>{contact.phone || 'N/A'}</strong></TableCell>
                <TableCell><strong>{contact.city || 'N/A'}</strong></TableCell>
                <TableCell><strong>{contact.custom_243 || 'N/A'}</strong></TableCell>
                <TableCell><strong>{contact.custom_134 || 'N/A'}</strong></TableCell>
                <TableCell><strong>{contact.custom_244 ? contact.custom_244.split('T')[0] : 'N/A'}</strong></TableCell>
                <TableCell><strong>{contact.custom_259 || 'N/A'}</strong></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderCallData = () => {
    if (!callData || Object.keys(callData).length === 0) {
      return <Typography>No call data available.</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(callData).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>
                  {typeof value === 'object' 
                    ? JSON.stringify(value) 
                    : String(value)
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getIntakeDischargeCount = () => {
    if (!contactData || !Array.isArray(contactData.contacts)) {
      console.log('No valid contact data available');
      return { intakeCount: 0, dischargeCount: 0 };
    }

    const [startDate, endDate] = dateRange;
    let intakeCount = 0;
    let dischargeCount = 0;

    contactData.contacts.forEach(contact => {
      const intakeDate = contact.custom_13;
      const dischargeDate = contact.custom_271;

      if (intakeDate && intakeDate >= startDate && intakeDate <= endDate) {
        intakeCount++;
      }

      if (dischargeDate && dischargeDate >= startDate && dischargeDate <= endDate) {
        dischargeCount++;
      }
    });

    return { intakeCount, dischargeCount };
  };

  const getProgramStatusCount = () => {
    if (!contactData || !Array.isArray(contactData.contacts)) {
      console.log('No valid contact data available');
      return {
        "Complete": 0,
        "In Progress": 0,
        "Not Complete": 0,
        "Transitional": 0
      };
    }

    const programStatuses = {
      "Complete": 0,
      "In Progress": 0,
      "Not Complete": 0,
      "Transitional": 0
    };

    contactData.contacts.forEach(contact => {
      const status = contact.custom_246;
      if (status in programStatuses) {
        programStatuses[status]++;
      }
    });

    return programStatuses;
  };

  const getWaitlistStatusCount = () => {
    if (!contactData || !Array.isArray(contactData.contacts)) {
      console.log('No valid contact data available');
      return {
        "Accepted": 0,
        "Not Accepted": 0,
        "In Progress": 0,
        "Inactive": 0
      };
    }

    const waitlistStatuses = {
      "Accepted": 0,
      "Not Accepted": 0,
      "In Progress": 0,
      "Inactive": 0
    };

    contactData.contacts.forEach(contact => {
      const status = contact.custom_243;
      if (status in waitlistStatuses) {
        waitlistStatuses[status]++;
      }
    });

    return waitlistStatuses;
  };

  const getFundingResultCount = () => {
    if (!contactData || !Array.isArray(contactData.contacts)) {
      console.log('No valid contact data available');
      return {
        "Approved": 0,
        "Denied": 0,
        "Pending": 0,
        "Not Applied": 0
      };
    }

    const fundingResults = {
      "Approved": 0,
      "Denied": 0,
      "Pending": 0,
      "Not Applied": 0
    };

    contactData.contacts.forEach(contact => {
      const result = contact.custom_259;
      if (result in fundingResults) {
        fundingResults[result]++;
      }
    });

    return fundingResults;
  };

  const renderWaitlistCountCard = () => {
    // Add debug logging
    console.log('Contact Data in renderWaitlistCountCard:', contactData);
    
    const totalWaitlist = contactData?.contacts?.length || 0;

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" gutterBottom align="center">
            Total Clients on Waitlist
          </Typography>
          <Typography variant="h3" align="center">
            {totalWaitlist}
          </Typography>
        </Paper>
      </Grid>
    );
  };

  const renderWaitlistDistribution = () => {
    const data = getWaitlistStatusCount();
    if (!data) return null;

    const statuses = ['Accepted', 'In Progress (waitlist)', 'Not Accepted', 'Inactive'];
    const colors = {
      'Accepted': '#4caf50', // green
      'In Progress (waitlist)': '#8bc34a', // light green
      'Not Accepted': '#f44336', // red
      'Inactive': '#ffeb3b' // yellow
    };

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Waitlist Status Distribution
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr', 
            gridTemplateRows: '1fr 1fr', 
            height: 'calc(100% - 40px)', 
            gap: 2 
          }}>
            {statuses.map((status) => (
              <Box 
                key={status} 
                sx={{ 
                  border: `2px solid ${colors[status]}`,
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  backgroundColor: `${colors[status]}22` // 13% opacity
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    position: 'absolute', 
                    top: '5px', 
                    left: '50%', 
                    transform: 'translateX(-50%)',
                    color: 'black',
                    textAlign: 'center',
                    width: '100%',
                    padding: '0 5px'
                  }}
                >
                  {status}
                </Typography>
                <Typography variant="h4" sx={{ color: 'black' }}>
                  {status === 'In Progress (waitlist)' ? (data['In Progress'] || 0) : (data[status] || 0)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Grid>
    );
  };

  const renderProgramStatusDistribution = () => {
    const data = getProgramStatusCount();
    if (!data) return null;

    const statuses = ["Complete", "In Progress", "Not Complete", "Transitional"];
    const colors = {
      "Complete": "#4caf50", // green
      "In Progress": "#8bc34a", // light green
      "Not Complete": "#f44336", // red
      "Transitional": "#ffeb3b" // yellow
    };

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Program Status Distribution
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr', 
            gridTemplateRows: '1fr 1fr', 
            height: 'calc(100% - 40px)', 
            gap: 2 
          }}>
            {statuses.map((status) => (
              <Box 
                key={status} 
                sx={{ 
                  border: `2px solid ${colors[status]}`,
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  backgroundColor: `${colors[status]}22` // 13% opacity
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    position: 'absolute', 
                    top: '5px', 
                    left: '50%', 
                    transform: 'translateX(-50%)',
                    color: 'black'
                  }}
                >
                  {status}
                </Typography>
                <Typography variant="h4" sx={{ color: 'black' }}>
                  {data[status] || 0}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Grid>
    );
  };

  const renderIntakeDischargeCount = () => {
    const { intakeCount, dischargeCount } = getIntakeDischargeCount();

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Intake and Discharge Count
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr', 
            height: 'calc(100% - 40px)', 
            gap: 2 
          }}>
            <Box 
              sx={{ 
                border: '2px solid #4caf50',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#4caf5022' // 13% opacity of green
              }}
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  position: 'absolute', 
                  top: '5px', 
                  left: '50%', 
                  transform: 'translateX(-50%)',
                  color: 'black'
                }}
              >
                Intake
              </Typography>
              <Typography variant="h4" sx={{ color: 'black' }}>
                {intakeCount}
              </Typography>
            </Box>
            <Box 
              sx={{ 
                border: '2px solid #f44336',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#f4433622' // 13% opacity of red
              }}
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  position: 'absolute', 
                  top: '5px', 
                  left: '50%', 
                  transform: 'translateX(-50%)',
                  color: 'black'
                }}
              >
                Discharge
              </Typography>
              <Typography variant="h4" sx={{ color: 'black' }}>
                {dischargeCount}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const renderTotalCallsCard = () => {
    const totalCalls = callData?.length || 0;

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" gutterBottom align="center">
            Total Calls Received
          </Typography>
          <Typography variant="h3" align="center">
            {totalCalls}
          </Typography>
        </Paper>
      </Grid>
    );
  };

  const renderCallStatusCard = () => {
    if (!callData) return null;

    const answeredCalls = callData.filter(call => call.answered);
    const unansweredCalls = callData.filter(call => !call.answered);
    const abandonedCalls = answeredCalls.filter(call => call.duration < 30);
    const completedCalls = answeredCalls.filter(call => call.duration >= 30);

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Call Status
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr 1fr', 
            height: 'calc(100% - 40px)', 
            gap: 2 
          }}>
            <Box 
              sx={{ 
                border: '2px solid #4caf50',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#4caf5022' // 13% opacity of green
              }}
            >
              <Typography variant="subtitle2" sx={{ position: 'absolute', top: '5px' }}>
                Completed
              </Typography>
              <Typography variant="h4">
                {completedCalls.length}
              </Typography>
            </Box>
            <Box 
              sx={{ 
                border: '2px solid #f44336',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#f4433622' // 13% opacity of red
              }}
            >
              <Typography variant="subtitle2" sx={{ position: 'absolute', top: '5px' }}>
                Unanswered
              </Typography>
              <Typography variant="h4">
                {unansweredCalls.length}
              </Typography>
            </Box>
            <Box 
              sx={{ 
                border: '2px solid #ffeb3b',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#ffeb3b22' // 13% opacity of yellow
              }}
            >
              <Typography variant="subtitle2" sx={{ position: 'absolute', top: '5px' }}>
                Abandoned
              </Typography>
              <Typography variant="h4">
                {abandonedCalls.length}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const renderFirstTimeCallersCard = () => {
    if (!callData) return null;

    const firstTimeCalls = callData.filter(call => call.first_call).length;

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" gutterBottom align="center">
            First Time Callers
          </Typography>
          <Typography variant="h3" align="center">
            {firstTimeCalls}
          </Typography>
        </Paper>
      </Grid>
    );
  };

  const renderTopCitiesCard = () => {
    if (!callData) return null;

    const cityCounts = callData.reduce((acc, call) => {
      const city = call.customer_city || 'Unknown';
      acc[city] = (acc[city] || 0) + 1;
      return acc;
    }, {});

    const topCities = Object.entries(cityCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Top 10 Cities
          </Typography>
          <TableContainer sx={{ maxHeight: 'calc(100% - 40px)', overflow: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>City</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topCities.map(([city, count]) => (
                  <TableRow key={city}>
                    <TableCell component="th" scope="row">
                      {city}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  const renderTopSourcesCard = () => {
    if (!callData) return null;

    const sourceCounts = callData.reduce((acc, call) => {
      const source = call.source || 'Unknown';
      acc[source] = (acc[source] || 0) + 1;
      return acc;
    }, {});

    const topSources = Object.entries(sourceCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Top 10 Sources
          </Typography>
          <TableContainer sx={{ maxHeight: 'calc(100% - 40px)', overflow: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topSources.map(([source, count]) => (
                  <TableRow key={source}>
                    <TableCell component="th" scope="row">
                      {source}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  const renderCallDurationDistribution = () => {
    if (!callData) return null;

    const durationBuckets = {
      '0-30s': 0,
      '31-60s': 0,
      '1-2m': 0,
      '2-5m': 0,
      '5-10m': 0,
      '10m+': 0
    };

    callData.forEach(call => {
      const duration = call.duration;
      if (duration <= 30) durationBuckets['0-30s']++;
      else if (duration <= 60) durationBuckets['31-60s']++;
      else if (duration <= 120) durationBuckets['1-2m']++;
      else if (duration <= 300) durationBuckets['2-5m']++;
      else if (duration <= 600) durationBuckets['5-10m']++;
      else durationBuckets['10m+']++;
    });

    const data = {
      labels: Object.keys(durationBuckets),
      datasets: [
        {
          label: 'Number of Calls',
          data: Object.values(durationBuckets),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Calls'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Duration'
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Call Duration Distribution'
        },
      },
    };

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Bar data={data} options={options} />
        </Paper>
      </Grid>
    );
  };

  const renderReferralSourcesCard = () => {
    if (!contactData?.contacts?.length) return null;

    const referralSources = contactData.contacts.reduce((acc, contact) => {
      const source = contact.custom_134 || 'Unknown';
      acc[source] = (acc[source] || 0) + 1;
      return acc;
    }, {});

    const sortedSources = Object.entries(referralSources)
      .sort((a, b) => b[1] - a[1]);

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Referral Sources
          </Typography>
          <TableContainer sx={{ maxHeight: 'calc(100% - 40px)', overflow: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedSources.map(([source, count]) => (
                  <TableRow key={source}>
                    <TableCell component="th" scope="row">
                      {source}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  const renderFundingSourcesCard = () => {
    if (!contactData?.contacts?.length) return null;

    const fundingSources = contactData.contacts.reduce((acc, contact) => {
      let sources = [];
      try {
        sources = JSON.parse(contact.custom_259 || '[]');
      } catch (e) {
        sources = [contact.custom_259];
      }
      sources.forEach(source => {
        if (source) {
          acc[source] = (acc[source] || 0) + 1;
        }
      });
      return acc;
    }, {});

    const sortedSources = Object.entries(fundingSources)
      .sort((a, b) => b[1] - a[1]);

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Funding Sources
          </Typography>
          <TableContainer sx={{ maxHeight: 'calc(100% - 40px)', overflow: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedSources.map(([source, count]) => (
                  <TableRow key={source}>
                    <TableCell component="th" scope="row">
                      {source}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  const renderIntakeDischargeByFundingCard = () => {
    if (!contactData?.contacts?.length) return null;

    const intakeByFunding = {};
    const dischargeByFunding = {};

    contactData.contacts.forEach(contact => {
      let fundingSources = [];
      try {
        fundingSources = JSON.parse(contact.custom_259 || '[]');
      } catch (e) {
        fundingSources = [contact.custom_259];
      }

      fundingSources.forEach(source => {
        if (source) {
          if (contact.custom_13) { // Intake date
            intakeByFunding[source] = (intakeByFunding[source] || 0) + 1;
          }
          if (contact.custom_271) { // Discharge date
            dischargeByFunding[source] = (dischargeByFunding[source] || 0) + 1;
          }
        }
      });
    });

    const renderFundingTable = (data, title) => (
      <Box sx={{ width: '50%', pr: 1 }}>
        <Typography variant="subtitle1" gutterBottom align="center">
          {title}
        </Typography>
        <TableContainer sx={{ maxHeight: 'calc(100% - 30px)', overflow: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Funding Type</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data)
                .sort((a, b) => b[1] - a[1])
                .map(([source, count]) => (
                  <TableRow key={source}>
                    <TableCell component="th" scope="row">
                      {source}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Intake and Discharge by Funding Type
          </Typography>
          <Box sx={{ display: 'flex', height: 'calc(100% - 40px)' }}>
            {renderFundingTable(intakeByFunding, 'Intake')}
            {renderFundingTable(dischargeByFunding, 'Discharge')}
          </Box>
        </Paper>
      </Grid>
    );
  };

  const renderProgramDistributionCard = () => {
    if (!contactData?.contacts?.length) return null;

    const programCounts = {
      "Detox Program": 0,
      "Ten (10) Day Intro Program": 0,
      "Forty-five (45) Day Program": 0,
      "Open Ended Program": 0,
      "Outpatient Program": 0
    };

    contactData.contacts.forEach(contact => {
      if (contact.custom_1834 === "1") programCounts["Detox Program"]++;
      if (contact.custom_1835 === "1") programCounts["Ten (10) Day Intro Program"]++;
      if (contact.custom_1836 === "1") programCounts["Forty-five (45) Day Program"]++;
      if (contact.custom_1837 === "1") programCounts["Open Ended Program"]++;
      if (contact.custom_1924 === "1") programCounts["Outpatient Program"]++;
    });

    const colors = [
      "#FF0000", // red
      "#FF7F00", // orange
      "#FFFF00", // yellow
      "#00FF00", // green
      "#0000FF"  // blue
    ];

    return (
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, height: '250px' }}>
          <Typography variant="h6" gutterBottom align="center">
            Program Distribution
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(5, 1fr)', 
            height: 'calc(100% - 40px)', 
            gap: 2 
          }}>
            {Object.entries(programCounts).map(([program, count], index) => (
              <Box 
                key={program} 
                sx={{ 
                  border: `2px solid ${colors[index]}`,
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  backgroundColor: `${colors[index]}22` // 13% opacity
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    position: 'absolute', 
                    top: '5px', 
                    left: '50%', 
                    transform: 'translateX(-50%)',
                    color: 'black',
                    textAlign: 'center',
                    width: '100%',
                    padding: '0 5px',
                    fontSize: '0.7rem'
                  }}
                >
                  {program}
                </Typography>
                <Typography variant="h4" sx={{ color: 'black' }}>
                  {count}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Grid>
    );
  };

  return (
    <>
      <Header />
      <Paper elevation={3} sx={{ p: 3, m: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Combined Dashboard
          </Typography>

          <Typography variant="body1" paragraph>
            This Combined Dashboard provides a comprehensive overview of both call data and contact information for Last Door Recovery Society. It allows staff to visualize and analyze key metrics across various aspects of the organization's operations.
          </Typography>

          <Box display="flex" gap={2} mb={2}>
            <TextField
              label="Start Date"
              type="date"
              value={dateRange[0]}
              onChange={(e) => setDateRange([e.target.value, dateRange[1]])}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={dateRange[1]}
              onChange={(e) => setDateRange([dateRange[0], e.target.value])}
              InputLabelProps={{ shrink: true }}
            />
            <Button variant="contained" onClick={handleGetData} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Get Data'}
            </Button>
          </Box>

          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          ) : (callData || contactData) && (
            <Box mt={4} width="100%">
              <Grid container spacing={3}>
                {/* Left column: Waitlist and Program Information */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                    {renderWaitlistCountCard()}
                    {renderIntakeDischargeCount()}
                    {renderWaitlistDistribution()}
                    {renderProgramStatusDistribution()}
                    {renderProgramDistributionCard()}
                    {renderIntakeDischargeByFundingCard()}
                    {renderReferralSourcesCard()}
                    {renderFundingSourcesCard()}
                  </Grid>
                </Grid>

                {/* Right column: Call Information */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                    {renderTotalCallsCard()}
                    {renderFirstTimeCallersCard()}
                    {renderCallStatusCard()}
                    {renderCallDurationDistribution()}
                    {renderTopCitiesCard()}
                    {renderTopSourcesCard()}
                  </Grid>
                </Grid>

                {/* Full-width cards at the bottom */}
                <Grid item xs={12}>
                  <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h6" gutterBottom>Contact Data Summary</Typography>
                    <Typography>Total Contacts: {contactData ? contactData.count : 0}</Typography>
                    {renderContactData()}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {/* <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h6" gutterBottom>Call Data Summary</Typography>
                    {renderCallData()}
                  </Paper> */}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DashboardCombined;