// src/pages/DashboardCalls.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Typography, Button, CircularProgress, Box, Paper, TextField, Grid } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import lastdoorLogo from '../resources/lastdoor.png';
import Header from '../components/Header';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];




const DashboardCalls = () => {
  const navigate = useNavigate();
  const { auth, logout } = useAuth();
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    new Date().toISOString().split('T')[0],
  ]);
  const [callData, setCallData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCallrailData = async (params) => {
    let allData = [];
    let page = 1;
    let totalPages = 1;

    if (!auth.token) {
      console.error('No authentication token found');
      logout();
      navigate('/');
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      console.error('API URL is not configured. Please check environment variables.');
      throw new Error('API URL is not configured');
    }

    while (page <= totalPages) {
      try {
        const url = `${process.env.REACT_APP_API_URL}/calls`;
        console.log('Fetching data from:', url);
        
        const response = await axios.get(url, {
          headers: { 
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          params: { ...params, page },
        });

        if (response.data.calls) {
          allData = [...allData, ...response.data.calls];
          totalPages = response.data.total_pages;
          page++;
        } else {
          console.error("Unexpected API response:", response.data);
          throw new Error('Invalid API response format');
        }
      } catch (error) {
        console.error("Error fetching data from backend:", {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data
        });
        
        if (error.response?.status === 401) {
          console.error('Authentication error - logging out');
          logout();
          navigate('/');
          return;
        }
        throw error;
      }
    }

    return { calls: allData };
  };

  const handleGetData = async () => {
    setLoading(true);
    try {
      const [startDate, endDate] = dateRange;
      const callsData = await fetchCallrailData({
        start_date: startDate,
        end_date: endDate,
        fields:
          "id,start_time,duration,call_type,first_call,source,medium,campaign,device_type,lead_status,value",
        per_page: 100,
      });
      setCallData(callsData);
    } catch (error) {
      console.error("Error fetching call data:", error);
      // Optionally, set an error state to display to the user
    }
    setLoading(false);
  };

  const renderCharts = () => {
    if (!callData || !callData.calls || callData.calls.length === 0) return null;

    const calls = callData.calls;

    // Call Type Distribution
    const callTypes = calls.reduce((acc, call) => {
      const type = call.duration < 35 ? 'Abandoned' : call.call_type;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
    const callTypeData = Object.entries(callTypes).map(([name, value]) => ({ name, value }));

    // Device Type Distribution
    const deviceTypes = calls.reduce((acc, call) => {
      acc[call.device_type] = (acc[call.device_type] || 0) + 1;
      return acc;
    }, {});
    const deviceTypeData = Object.entries(deviceTypes).map(([name, value]) => ({ name, value }));

    // Call Duration Distribution
    const durationIntervals = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300];
    const callDurations = calls.reduce((acc, call) => {
      const interval = durationIntervals.find(i => call.duration <= i) || '300+';
      acc[interval] = (acc[interval] || 0) + 1;
      return acc;
    }, {});
    const durationData = Object.entries(callDurations).map(([name, value]) => ({ name: `${name}s`, value }));

    // Top 10 Call Sources
    const callSources = calls.reduce((acc, call) => {
      acc[call.source] = (acc[call.source] || 0) + 1;
      return acc;
    }, {});
    const topSources = Object.entries(callSources)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([name, value]) => ({ name, value }));

    // First Time vs Returning Callers
    const callerTypes = calls.reduce((acc, call) => {
      acc[call.first_call ? 'First Time' : 'Returning'] += 1;
      return acc;
    }, { 'First Time': 0, 'Returning': 0 });
    const callerTypeData = Object.entries(callerTypes).map(([name, value]) => ({ name, value }));

    // Customer City and State Distribution
    const locationData = calls.reduce((acc, call) => {
      const state = call.customer_state || 'Unknown';
      const city = call.customer_city || 'Unknown';
      if (!acc[state]) acc[state] = {};
      acc[state][city] = (acc[state][city] || 0) + 1;
      return acc;
    }, {});

    console.log('Location Data:', locationData); // Log the location data for debugging

    const locationCharts = Object.entries(locationData).map(([state, cities]) => {
      const cityData = Object.entries(cities)
        .sort((a, b) => b[1] - a[1])
        .map(([name, value]) => ({ name, value }));

      console.log(`${state} City Data:`, cityData); // Log city data for each state

      return (
        <Grid item xs={12} key={state}>
          <Typography variant="h6" gutterBottom>{state} - Cities</Typography>
          <ResponsiveContainer width="100%" height={Math.max(300, cityData.length * 30)}>
            <BarChart data={cityData} layout="vertical" margin={{ left: 200, right: 20, top: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" width={180} />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      );
    });

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Call Type Distribution</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={callTypeData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
                {callTypeData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Device Type Distribution</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={deviceTypeData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
                {deviceTypeData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Call Duration Distribution</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={durationData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Top 10 Call Sources</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={topSources} layout="vertical" margin={{ left: 200 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" width={200} />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>First Time vs Returning Callers</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={callerTypeData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
                {callerTypeData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        {locationCharts}
      </Grid>
    );
  };

  return (
    <>
      <Header />
      <Paper elevation={3} sx={{ p: 3, m: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">

          <Typography variant="h4" gutterBottom>
            Incoming Calls Dashboard v0.2
          </Typography>

          <Typography variant="body1" paragraph>
            This Incoming Calls Dashboard tool provides a comprehensive overview of call data for Last Door
            Recovery Society. It allows staff to visualize and analyze key metrics for incoming calls, including
            total call counts, call durations, and trends over time.
          </Typography>

          <Box display="flex" gap={2} mb={2}>
            <TextField
              label="Start Date"
              type="date"
              value={dateRange[0]}
              onChange={(e) => setDateRange([e.target.value, dateRange[1]])}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={dateRange[1]}
              onChange={(e) => setDateRange([dateRange[0], e.target.value])}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Button variant="contained" onClick={handleGetData} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Get Data'}
          </Button>

          {callData && (
            <Box mt={4} width="100%">
              <Typography variant="h6" gutterBottom>Total Calls: {callData.calls.length}</Typography>
              {renderCharts()}
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DashboardCalls;
